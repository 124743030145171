import React from "react";

function Footer() {
  return (
    <div className="footer bg-dark text-light text-center">
      &copy; All Rights Reserved 2023 || Made by Me with ❤️
    </div>
  );
}

export default Footer;
