import React from 'react'
import EduTimeline from './EduTimeline'

function Education() {
  return (
    <EduTimeline />
  )
}

export default Education
